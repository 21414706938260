// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// AddUserRegionalPlanningPartnerAssignmentHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    regionalPlanningPartnerIdentifier: number;
    userId: string | null;
    roleIds: string[];
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            regionalPlanningPartnerIdentifier: 0,
            userId: '00000000-0000-0000-0000-000000000000',
            roleIds: [],
        },
        initValues);
    },
    regionalPlanningPartnerIdentifier: 'regionalPlanningPartnerIdentifier',
    userId: 'userId',
    roleIds: 'roleIds',
}

interface Result extends ResultDTO {
    userId: string | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            userId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    userId: 'userId',
}

export { Request, Result };