// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SearchForInstituionCoursesHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import YearTermDTO from '../models/YearTermDTO';
import InstitutionCourseDetailDTO from '../models/InstitutionCourseDetailDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    disciplineId: string | null | null;
    catalogNumber: string | null;
    title: string | null;
    institutionId: string | null | null;
    installTerm: YearTermDTO | null;
    includeRetired: boolean | null | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            disciplineId: null,
            catalogNumber: "",
            title: "",
            institutionId: null,
            installTerm: null,
            includeRetired: null,
        },
        initValues);
    },
    disciplineId: 'disciplineId',
    catalogNumber: 'catalogNumber',
    title: 'title',
    institutionId: 'institutionId',
    installTerm: 'installTerm',
    includeRetired: 'includeRetired',
}

interface Result extends ResultDTO {
    institutionCourseDetails: InstitutionCourseDetailDTO[] | null;
    exceedsNumberOfCourses: boolean;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            institutionCourseDetails: [],
            exceedsNumberOfCourses: false,
        },
        initValues);
    },
    institutionCourseDetails: 'institutionCourseDetails',
    exceedsNumberOfCourses: 'exceedsNumberOfCourses',
}

export { Request, Result };