// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetTransferMajorDisciplineFrameworkElementCoursesHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import YearTermDTO from '../models/YearTermDTO';
import TransferMajorDisciplineFrameworkElementCourseDTO from '../models/TransferMajorDisciplineFrameworkElementCourseDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    transferMajorDisciplineFrameworkId: number;
    instutionId: string | null | null;
    installTerm: YearTermDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            transferMajorDisciplineFrameworkId: 0,
            instutionId: null,
            installTerm: null,
        },
        initValues);
    },
    transferMajorDisciplineFrameworkId: 'transferMajorDisciplineFrameworkId',
    instutionId: 'instutionId',
    installTerm: 'installTerm',
}

interface Result extends ResultDTO {
    transferMajorDisciplineFrameworkElementCourses: TransferMajorDisciplineFrameworkElementCourseDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            transferMajorDisciplineFrameworkElementCourses: [],
        },
        initValues);
    },
    transferMajorDisciplineFrameworkElementCourses: 'transferMajorDisciplineFrameworkElementCourses',
}

export { Request, Result };