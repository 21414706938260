// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetProgramModificationChangeRequestStep3Handler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ChangeRequestDTO from '../models/ChangeRequestDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    changeRequestId: string | null;
    programAwardId: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            changeRequestId: '00000000-0000-0000-0000-000000000000',
            programAwardId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    changeRequestId: 'changeRequestId',
    programAwardId: 'programAwardId',
}

interface Result extends ResultDTO {
    changeRequest: ChangeRequestDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            changeRequest: null,
        },
        initValues);
    },
    changeRequest: 'changeRequest',
}

export { Request, Result };