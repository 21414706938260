// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionCourseDTO from './InstitutionCourseDTO';
import InstitutionDTO from './InstitutionDTO';
import DistrictDTO from './DistrictDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SecondaryCourseDTO extends EntitySelectorDTO { 
	id: string | null;
	secondaryCourseId: number;
	academicYear: number;
	enrollment: number;
	localCourseNumber: string | null;
	courseName: string | null;
	institutionCourseId: string | null | null;
	institutionCourse: InstitutionCourseDTO | null;
	carnegieUnit: number;
	communityCollegeIdentifier: number;
	communityCollegeId: string | null | null;
	communityCollege: InstitutionDTO | null;
	districtIdentifier: number;
	districtId: string | null | null;
	district: DistrictDTO | null;
	sced: string | null;
	display: string | null;
}

const SecondaryCourseDTO = {
    className: 'SecondaryCourseDTO',
    create: (initValues?: Partial<SecondaryCourseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			secondaryCourseId: 0,
			academicYear: 0,
			enrollment: 0,
			localCourseNumber: "",
			courseName: "",
			institutionCourseId: null,
			institutionCourse: null,
			carnegieUnit: 0,
			communityCollegeIdentifier: 0,
			communityCollegeId: null,
			communityCollege: null,
			districtIdentifier: 0,
			districtId: null,
			district: null,
			sced: "",
			display: "",
        },
        initValues);
    },
	id: 'id',
	secondaryCourseId: 'secondaryCourseId',
	academicYear: 'academicYear',
	enrollment: 'enrollment',
	localCourseNumber: 'localCourseNumber',
	courseName: 'courseName',
	institutionCourseId: 'institutionCourseId',
	institutionCourse: 'institutionCourse',
	carnegieUnit: 'carnegieUnit',
	communityCollegeIdentifier: 'communityCollegeIdentifier',
	communityCollegeId: 'communityCollegeId',
	communityCollege: 'communityCollege',
	districtIdentifier: 'districtIdentifier',
	districtId: 'districtId',
	district: 'district',
	sced: 'sced',
	display: 'display',
};

export default SecondaryCourseDTO;