// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as EPRExportHandler from '../handlerModels/EPRExportHandler';

export class DownloadsApiService extends BaseApi {

    // post: api/downloads/exportEPR
    public exportEPR(request: EPRExportHandler.Request): Promise<any> {
        let url = `api/downloads/exportEPR`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(request, 'post', url, true, false);
    }

    // get: api/downloads/noiReport?changeRequestId=${encodeURIComponent(changeRequestId)}
    public exportNOI(changeRequestId: string): Promise<any> {
        let url = `api/downloads/noiReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (changeRequestId != null) {
            url += `${prefix}changeRequestId=${encodeURIComponent(changeRequestId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/downloads/noiICCPHSEReport?changeRequestId=${encodeURIComponent(changeRequestId)}&noticeOfIntentId=${encodeURIComponent(noticeOfIntentId)}
    public exportNOIICCPHSE(changeRequestId: string, noticeOfIntentId: string): Promise<any> {
        let url = `api/downloads/noiICCPHSEReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (changeRequestId != null) {
            url += `${prefix}changeRequestId=${encodeURIComponent(changeRequestId)}`;
            prefix = '&';
        }
        if (noticeOfIntentId != null) {
            url += `${prefix}noticeOfIntentId=${encodeURIComponent(noticeOfIntentId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/downloads/programReport?changeRequestId=${encodeURIComponent(changeRequestId)}
    public exportProgram(changeRequestId: string): Promise<any> {
        let url = `api/downloads/programReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (changeRequestId != null) {
            url += `${prefix}changeRequestId=${encodeURIComponent(changeRequestId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/downloads/newProgramProposal?changeRequestId=${encodeURIComponent(changeRequestId)}
    public newProgramProposal(changeRequestId: string): Promise<any> {
        let url = `api/downloads/newProgramProposal`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (changeRequestId != null) {
            url += `${prefix}changeRequestId=${encodeURIComponent(changeRequestId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/downloads/laborMarketInformationReport?changeRequestId=${encodeURIComponent(changeRequestId)}
    public laborMarketInformationReport(changeRequestId: string): Promise<any> {
        let url = `api/downloads/laborMarketInformationReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (changeRequestId != null) {
            url += `${prefix}changeRequestId=${encodeURIComponent(changeRequestId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }

    // get: api/downloads/programMasterReport?termId=${encodeURIComponent(termId)}&year=${year}&institutionId=${encodeURIComponent(institutionId)}
    public exportProgramMasterReport(termId: string, year: number, institutionId: string): Promise<any> {
        let url = `api/downloads/programMasterReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (termId != null) {
            url += `${prefix}termId=${encodeURIComponent(termId)}`;
            prefix = '&';
        }
        if (year != null) {
            url += `${prefix}year=${year}`;
            prefix = '&';
        }
        if (institutionId != null) {
            url += `${prefix}institutionId=${encodeURIComponent(institutionId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new DownloadsApiService();
export default service;
