// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AwardTypeDTO from './AwardTypeDTO';
import CipNumberDTO from './CipNumberDTO';
import IowaVariationDTO from './IowaVariationDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface CourseProgramDataTableDTO extends EntitySelectorDTO { 
	id: string | null;
	programId: string | null;
	title: string | null;
	awardTypeIds: number[];
	awards: AwardTypeDTO[] | null;
	cipNumberId: string | null;
	cipNumber: CipNumberDTO | null;
	iowaVariationId: number;
	iowaVariation: IowaVariationDTO | null;
}

const CourseProgramDataTableDTO = {
    className: 'CourseProgramDataTableDTO',
    create: (initValues?: Partial<CourseProgramDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			programId: '00000000-0000-0000-0000-000000000000',
			title: "",
			awardTypeIds: [],
			awards: [],
			cipNumberId: '00000000-0000-0000-0000-000000000000',
			cipNumber: null,
			iowaVariationId: 0,
			iowaVariation: null,
        },
        initValues);
    },
	id: 'id',
	programId: 'programId',
	title: 'title',
	awardTypeIds: 'awardTypeIds',
	awards: 'awards',
	cipNumberId: 'cipNumberId',
	cipNumber: 'cipNumber',
	iowaVariationId: 'iowaVariationId',
	iowaVariation: 'iowaVariation',
};

export default CourseProgramDataTableDTO;