// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveResourceLinkDetailHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResourceLinkDTO from '../models/ResourceLinkDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    resourceLinkId: string | null;
    url: string | null;
    title: string | null;
    category: string | null;
    description: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            resourceLinkId: '00000000-0000-0000-0000-000000000000',
            url: "",
            title: "",
            category: "",
            description: "",
        },
        initValues);
    },
    resourceLinkId: 'resourceLinkId',
    url: 'url',
    title: 'title',
    category: 'category',
    description: 'description',
}

interface Result extends ResultDTO {
    resourceLink: ResourceLinkDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            resourceLink: null,
        },
        initValues);
    },
    resourceLink: 'resourceLink',
}

export { Request, Result };