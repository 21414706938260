// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudySectionTemplateDTO from './SelfStudySectionTemplateDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyTemplateDTO extends EntitySelectorDTO { 
	id: string | null;
	academicYear: number;
	selfStudySectionTemplates: SelfStudySectionTemplateDTO[] | null;
	minimumCareerAcademyScore: number;
}

const SelfStudyTemplateDTO = {
    className: 'SelfStudyTemplateDTO',
    create: (initValues?: Partial<SelfStudyTemplateDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			academicYear: 0,
			selfStudySectionTemplates: [],
			minimumCareerAcademyScore: 0,
        },
        initValues);
    },
	id: 'id',
	academicYear: 'academicYear',
	selfStudySectionTemplates: 'selfStudySectionTemplates',
	minimumCareerAcademyScore: 'minimumCareerAcademyScore',
};

export default SelfStudyTemplateDTO;