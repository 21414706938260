// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetSecondaryProgramAttachmentsHandler from '../handlerModels/GetSecondaryProgramAttachmentsHandler';

export class SecondaryProgramAttachmentApiService extends BaseApi {

    // post: api/secondaryProgramAttchments/get
    public getSecondaryProgramAttachment(getSecondaryProgramAttachments: GetSecondaryProgramAttachmentsHandler.Request): Promise<GetSecondaryProgramAttachmentsHandler.Result> {
        let url = `api/secondaryProgramAttchments/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryProgramAttachmentsHandler.Result>(getSecondaryProgramAttachments, 'post', url, true, false);
    }
}
var service = new SecondaryProgramAttachmentApiService();
export default service;
