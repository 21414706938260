// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetSecondaryProgramCoursesHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SecondaryProgramCourseDTO from '../models/SecondaryProgramCourseDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYearId: number;
    secondaryProgramId: number | null | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYearId: 0,
            secondaryProgramId: null,
        },
        initValues);
    },
    academicYearId: 'academicYearId',
    secondaryProgramId: 'secondaryProgramId',
}

interface Result extends ResultDTO {
    secondaryProgramCourse: SecondaryProgramCourseDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            secondaryProgramCourse: [],
        },
        initValues);
    },
    secondaryProgramCourse: 'secondaryProgramCourse',
}

export { Request, Result };