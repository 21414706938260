// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AzureBlobFileDTO from './AzureBlobFileDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SecondaryProgramAttachmentDTO extends EntitySelectorDTO { 
	id: string | null;
	secondaryProgramIdentifier: number;
	academicYear: number;
	azureBlobFileId: string | null;
	azureBlobFile: AzureBlobFileDTO | null;
}

const SecondaryProgramAttachmentDTO = {
    className: 'SecondaryProgramAttachmentDTO',
    create: (initValues?: Partial<SecondaryProgramAttachmentDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			secondaryProgramIdentifier: 0,
			academicYear: 0,
			azureBlobFileId: '00000000-0000-0000-0000-000000000000',
			azureBlobFile: null,
        },
        initValues);
    },
	id: 'id',
	secondaryProgramIdentifier: 'secondaryProgramIdentifier',
	academicYear: 'academicYear',
	azureBlobFileId: 'azureBlobFileId',
	azureBlobFile: 'azureBlobFile',
};

export default SecondaryProgramAttachmentDTO;