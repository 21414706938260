// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveSelfStudyResponseUserHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SelfStudyDTO from '../models/SelfStudyDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    selfStudyId: string | null;
    userId: string | null;
    selfStudyResponseTypeId: number;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            selfStudyId: '00000000-0000-0000-0000-000000000000',
            userId: '00000000-0000-0000-0000-000000000000',
            selfStudyResponseTypeId: 0,
        },
        initValues);
    },
    selfStudyId: 'selfStudyId',
    userId: 'userId',
    selfStudyResponseTypeId: 'selfStudyResponseTypeId',
}

interface Result extends ResultDTO {
    selfStudy: SelfStudyDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            selfStudy: null,
        },
        initValues);
    },
    selfStudy: 'selfStudy',
}

export { Request, Result };