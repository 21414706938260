// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudyResponseDTO from './SelfStudyResponseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyResponsePreviousGoalDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudyResponseId: string | null;
	selfStudyResponse: SelfStudyResponseDTO | null;
	section: string | null;
	actionStepsAndTimeline: string | null;
	measureOfSuccess: string | null;
	results: string | null;
	sectionTitle: string[];
	order: number;
}

const SelfStudyResponsePreviousGoalDTO = {
    className: 'SelfStudyResponsePreviousGoalDTO',
    create: (initValues?: Partial<SelfStudyResponsePreviousGoalDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudyResponseId: '00000000-0000-0000-0000-000000000000',
			selfStudyResponse: null,
			section: "",
			actionStepsAndTimeline: "",
			measureOfSuccess: "",
			results: "",
			sectionTitle: [],
			order: 0,
        },
        initValues);
    },
	id: 'id',
	selfStudyResponseId: 'selfStudyResponseId',
	selfStudyResponse: 'selfStudyResponse',
	section: 'section',
	actionStepsAndTimeline: 'actionStepsAndTimeline',
	measureOfSuccess: 'measureOfSuccess',
	results: 'results',
	sectionTitle: 'sectionTitle',
	order: 'order',
};

export default SelfStudyResponsePreviousGoalDTO;