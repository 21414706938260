// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudyResponseDTO from './SelfStudyResponseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ProgramConsultantCommentDataTableDTO extends EntitySelectorDTO { 
	selfStudyResponseId: string | null;
	selfStudyResponse: SelfStudyResponseDTO | null;
	selfStudyId: string | null;
	rpp: string | null;
	communityCollege: string | null;
	district: string | null;
	serviceArea: string | null;
	cipNumber: string | null;
	program: string | null;
	submitter: string | null;
	responseType: string | null;
	reviewYear: number;
}

const ProgramConsultantCommentDataTableDTO = {
    className: 'ProgramConsultantCommentDataTableDTO',
    create: (initValues?: Partial<ProgramConsultantCommentDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			selfStudyResponseId: '00000000-0000-0000-0000-000000000000',
			selfStudyResponse: null,
			selfStudyId: '00000000-0000-0000-0000-000000000000',
			rpp: "",
			communityCollege: "",
			district: "",
			serviceArea: "",
			cipNumber: "",
			program: "",
			submitter: "",
			responseType: "",
			reviewYear: 0,
        },
        initValues);
    },
	selfStudyResponseId: 'selfStudyResponseId',
	selfStudyResponse: 'selfStudyResponse',
	selfStudyId: 'selfStudyId',
	rpp: 'rpp',
	communityCollege: 'communityCollege',
	district: 'district',
	serviceArea: 'serviceArea',
	cipNumber: 'cipNumber',
	program: 'program',
	submitter: 'submitter',
	responseType: 'responseType',
	reviewYear: 'reviewYear',
};

export default ProgramConsultantCommentDataTableDTO;