// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudySectionComponentResponseDTO from './SelfStudySectionComponentResponseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyResponseConsultantReviewQuestionDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudySectionComponentId: string | null;
	selfStudySectionComponentResponse: SelfStudySectionComponentResponseDTO | null;
	consultantAnswer: boolean | null | null;
	comment: string | null;
}

const SelfStudyResponseConsultantReviewQuestionDTO = {
    className: 'SelfStudyResponseConsultantReviewQuestionDTO',
    create: (initValues?: Partial<SelfStudyResponseConsultantReviewQuestionDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudySectionComponentId: '00000000-0000-0000-0000-000000000000',
			selfStudySectionComponentResponse: null,
			consultantAnswer: null,
			comment: "",
        },
        initValues);
    },
	id: 'id',
	selfStudySectionComponentId: 'selfStudySectionComponentId',
	selfStudySectionComponentResponse: 'selfStudySectionComponentResponse',
	consultantAnswer: 'consultantAnswer',
	comment: 'comment',
};

export default SelfStudyResponseConsultantReviewQuestionDTO;