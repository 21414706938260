// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SearchForIDOEConsultantUserHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import UserDTO from '../models/UserDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    searchString: string | null;
    serviceAreaIdentifier: number | null | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            searchString: "",
            serviceAreaIdentifier: null,
        },
        initValues);
    },
    searchString: 'searchString',
    serviceAreaIdentifier: 'serviceAreaIdentifier',
}

interface Result extends ResultDTO {
    users: UserDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            users: [],
        },
        initValues);
    },
    users: 'users',
}

export { Request, Result };