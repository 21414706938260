// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import DistrictDTO from './DistrictDTO';
import DistrictContactTypeDTO from './DistrictContactTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface DistrictContactDTO extends EntitySelectorDTO { 
	id: string | null;
	districtId: string | null | null;
	district: DistrictDTO | null;
	districtContactTypeId: number | null | null;
	districtContactType: DistrictContactTypeDTO | null;
	fullName: string | null;
	email: string | null;
	phone: string | null;
	title: string | null;
}

const DistrictContactDTO = {
    className: 'DistrictContactDTO',
    create: (initValues?: Partial<DistrictContactDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			districtId: null,
			district: null,
			districtContactTypeId: null,
			districtContactType: null,
			fullName: "",
			email: "",
			phone: "",
			title: "",
        },
        initValues);
    },
	id: 'id',
	districtId: 'districtId',
	district: 'district',
	districtContactTypeId: 'districtContactTypeId',
	districtContactType: 'districtContactType',
	fullName: 'fullName',
	email: 'email',
	phone: 'phone',
	title: 'title',
};

export default DistrictContactDTO;