// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import AzureBlobFileDTO from './AzureBlobFileDTO';
import ResourceCategoryDTO from './ResourceCategoryDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ResourceFileDTO extends EntitySelectorDTO { 
	id: string | null;
	title: string | null;
	azureBlobFileId: string | null | null;
	azureBlobFile: AzureBlobFileDTO | null;
	resourceCategory: ResourceCategoryDTO | null;
	categoryTitle: string | null;
	description: string | null;
}

const ResourceFileDTO = {
    className: 'ResourceFileDTO',
    create: (initValues?: Partial<ResourceFileDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			title: "",
			azureBlobFileId: null,
			azureBlobFile: null,
			resourceCategory: null,
			categoryTitle: "",
			description: "",
        },
        initValues);
    },
	id: 'id',
	title: 'title',
	azureBlobFileId: 'azureBlobFileId',
	azureBlobFile: 'azureBlobFile',
	resourceCategory: 'resourceCategory',
	categoryTitle: 'categoryTitle',
	description: 'description',
};

export default ResourceFileDTO;