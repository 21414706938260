// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveFederalClusterDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import FederalClusterDTO from '../models/FederalClusterDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    federalClusterId: string | null;
    federalCluster: FederalClusterDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            federalClusterId: '00000000-0000-0000-0000-000000000000',
            federalCluster: null,
        },
        initValues);
    },
    federalClusterId: 'federalClusterId',
    federalCluster: 'federalCluster',
}

interface Result extends ResultDTO {
    federalCluster: FederalClusterDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            federalCluster: null,
        },
        initValues);
    },
    federalCluster: 'federalCluster',
}

export { Request, Result };