// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import RegionalPlanningPartnerDTO from '../models/RegionalPlanningPartnerDTO';
import * as GetRegionPlanningPartnerDetailsHandler from '../handlerModels/GetRegionPlanningPartnerDetailsHandler';
import * as GetRegionalPlanningPartnersHandler from '../handlerModels/GetRegionalPlanningPartnersHandler';
import * as GetRegionPlanningPartnerForUserHandler from '../handlerModels/GetRegionPlanningPartnerForUserHandler';
import * as GetRegionalPlanningPartnersByAcademicYearHandler from '../handlerModels/GetRegionalPlanningPartnersByAcademicYearHandler';
import * as SaveRegionalPlanningPartnerDetailsHandler from '../handlerModels/SaveRegionalPlanningPartnerDetailsHandler';
import * as DeleteRegionalPlanningPartnerHandler from '../handlerModels/DeleteRegionalPlanningPartnerHandler';
import * as SearchForRppUserHandler from '../handlerModels/SearchForRppUserHandler';
import * as GetAllRegionalPlanningPartnersHandler from '../handlerModels/GetAllRegionalPlanningPartnersHandler';

export class RegionalPlanningPartnerApiService extends BaseApi {

    // post: api/regionalPlanningPartners?academicYear=${academicYear}
    public getRegionalPlanningPartnerTableData(tableRequest: TableRequestDTO, academicYear: number): Promise<TableResponseDTO<RegionalPlanningPartnerDTO>> {
        let url = `api/regionalPlanningPartners`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<RegionalPlanningPartnerDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/getRPPDetails
    public getRegionDetails(getRegionPlanningPartnerDetails: GetRegionPlanningPartnerDetailsHandler.Request): Promise<GetRegionPlanningPartnerDetailsHandler.Result> {
        let url = `api/regionalPlanningPartners/getRPPDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRegionPlanningPartnerDetailsHandler.Result>(getRegionPlanningPartnerDetails, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/regionalPlanningPartners/${academicYear}
    public getRegionalPlanningPartners(academicYear: number): Promise<GetRegionalPlanningPartnersHandler.Result> {
        let url = `api/regionalPlanningPartners/regionalPlanningPartners/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRegionalPlanningPartnersHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/regionalPlanningPartnersByUser
    public getRegionalPlanningPartnersByUser(getRegionPlanningPartnerForUser: GetRegionPlanningPartnerForUserHandler.Request): Promise<GetRegionPlanningPartnerForUserHandler.Result> {
        let url = `api/regionalPlanningPartners/regionalPlanningPartnersByUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRegionPlanningPartnerForUserHandler.Result>(getRegionPlanningPartnerForUser, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/regionalPlanningPartnersByAcademicYear
    public getRegionalPlanningPartnersByAcademicYear(getRegionalPlanningPartnersByAcademicYear: GetRegionalPlanningPartnersByAcademicYearHandler.Request): Promise<GetRegionalPlanningPartnersByAcademicYearHandler.Result> {
        let url = `api/regionalPlanningPartners/regionalPlanningPartnersByAcademicYear`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetRegionalPlanningPartnersByAcademicYearHandler.Result>(getRegionalPlanningPartnersByAcademicYear, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/save
    public saveRegionalPlanningPartnerDetails(saveRegionalPlanningPartnerDetails: SaveRegionalPlanningPartnerDetailsHandler.Request): Promise<SaveRegionalPlanningPartnerDetailsHandler.Result> {
        let url = `api/regionalPlanningPartners/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveRegionalPlanningPartnerDetailsHandler.Result>(saveRegionalPlanningPartnerDetails, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/delete
    public deleteRegionalPlanningPartner(deleteRegionalPlanningPartner: DeleteRegionalPlanningPartnerHandler.Request): Promise<DeleteRegionalPlanningPartnerHandler.Result> {
        let url = `api/regionalPlanningPartners/delete`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteRegionalPlanningPartnerHandler.Result>(deleteRegionalPlanningPartner, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/searchRPPUsers
    public searchRPPUsers(searchForRppUser: SearchForRppUserHandler.Request): Promise<SearchForRppUserHandler.Result> {
        let url = `api/regionalPlanningPartners/searchRPPUsers`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForRppUserHandler.Result>(searchForRppUser, 'post', url, true, false);
    }

    // post: api/regionalPlanningPartners/getAllRpps
    public getAllRpps(): Promise<GetAllRegionalPlanningPartnersHandler.Result> {
        let url = `api/regionalPlanningPartners/getAllRpps`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAllRegionalPlanningPartnersHandler.Result>(null, 'post', url, true, false);
    }
}
var service = new RegionalPlanningPartnerApiService();
export default service;
