// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudySectionComponentTypeDTO from './SelfStudySectionComponentTypeDTO';
import SelfStudyResponseTemplateConsultantReviewQuestionDTO from './SelfStudyResponseTemplateConsultantReviewQuestionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudySectionComponentTemplateDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudySectionComponentTypeId: number | null | null;
	selfStudySectionComponentType: SelfStudySectionComponentTypeDTO | null;
	rankingQuestionIdentifier: string | null;
	numberOfPreviousGoals: number | null | null;
	numberOfNewGoals: number | null | null;
	primaryText: string | null;
	order: number;
	careerAcademy: boolean | null | null;
	programOfStudyTenets: string | null;
	rppInstructions: string | null;
	selfStudySectionComponentScenarios: string[];
	selfStudyResponseTemplateConsultantReviewQuestions: SelfStudyResponseTemplateConsultantReviewQuestionDTO[] | null;
}

const SelfStudySectionComponentTemplateDTO = {
    className: 'SelfStudySectionComponentTemplateDTO',
    create: (initValues?: Partial<SelfStudySectionComponentTemplateDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudySectionComponentTypeId: null,
			selfStudySectionComponentType: null,
			rankingQuestionIdentifier: "",
			numberOfPreviousGoals: null,
			numberOfNewGoals: null,
			primaryText: "",
			order: 0,
			careerAcademy: null,
			programOfStudyTenets: "",
			rppInstructions: "",
			selfStudySectionComponentScenarios: [],
			selfStudyResponseTemplateConsultantReviewQuestions: [],
        },
        initValues);
    },
	id: 'id',
	selfStudySectionComponentTypeId: 'selfStudySectionComponentTypeId',
	selfStudySectionComponentType: 'selfStudySectionComponentType',
	rankingQuestionIdentifier: 'rankingQuestionIdentifier',
	numberOfPreviousGoals: 'numberOfPreviousGoals',
	numberOfNewGoals: 'numberOfNewGoals',
	primaryText: 'primaryText',
	order: 'order',
	careerAcademy: 'careerAcademy',
	programOfStudyTenets: 'programOfStudyTenets',
	rppInstructions: 'rppInstructions',
	selfStudySectionComponentScenarios: 'selfStudySectionComponentScenarios',
	selfStudyResponseTemplateConsultantReviewQuestions: 'selfStudyResponseTemplateConsultantReviewQuestions',
};

export default SelfStudySectionComponentTemplateDTO;