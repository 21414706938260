// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetChangeRequestTypeHandler from '../handlerModels/GetChangeRequestTypeHandler';
import * as SaveChangeRequestTypeHandler from '../handlerModels/SaveChangeRequestTypeHandler';

export class ChangeRequestTypeApiService extends BaseApi {

    // get: api/changeRequestType/${changeRequestTypeId}
    public getDetails(changeRequestTypeId: number): Promise<GetChangeRequestTypeHandler.Result> {
        let url = `api/changeRequestType/${changeRequestTypeId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetChangeRequestTypeHandler.Result>(null, 'get', url, true, false);
    }

    // post: api/changeRequestType/save
    public save(saveChangeRequestType: SaveChangeRequestTypeHandler.Request): Promise<SaveChangeRequestTypeHandler.Result> {
        let url = `api/changeRequestType/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveChangeRequestTypeHandler.Result>(saveChangeRequestType, 'post', url, true, false);
    }
}
var service = new ChangeRequestTypeApiService();
export default service;
