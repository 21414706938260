// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class SelfStudySectionComponentType {
    public static readonly RANKINGQUESTION: number = 1;
    public static readonly FREETEXTQUESTION: number = 2;
    public static readonly INSTRUCTIONS: number = 3;
    public static readonly PROGRAMOVERVIEW: number = 4;
    public static readonly PREVIOUSGOALS: number = 5;
    public static readonly NEWGOALS: number = 6;
    public static readonly CONSULTANTREVIEW: number = 7;
}
