// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudySectionComponentTemplateDTO from './SelfStudySectionComponentTemplateDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudyResponseTemplateConsultantReviewQuestionDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudySectionComponentTemplateId: string | null;
	selfStudySectionComponentTemplate: SelfStudySectionComponentTemplateDTO | null;
	order: number;
	question: string | null;
}

const SelfStudyResponseTemplateConsultantReviewQuestionDTO = {
    className: 'SelfStudyResponseTemplateConsultantReviewQuestionDTO',
    create: (initValues?: Partial<SelfStudyResponseTemplateConsultantReviewQuestionDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudySectionComponentTemplateId: '00000000-0000-0000-0000-000000000000',
			selfStudySectionComponentTemplate: null,
			order: 0,
			question: "",
        },
        initValues);
    },
	id: 'id',
	selfStudySectionComponentTemplateId: 'selfStudySectionComponentTemplateId',
	selfStudySectionComponentTemplate: 'selfStudySectionComponentTemplate',
	order: 'order',
	question: 'question',
};

export default SelfStudyResponseTemplateConsultantReviewQuestionDTO;