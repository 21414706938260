// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ResourceCategoryDTO extends EntitySelectorDTO { 
	id: string | null;
	name: string | null;
}

const ResourceCategoryDTO = {
    className: 'ResourceCategoryDTO',
    create: (initValues?: Partial<ResourceCategoryDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			name: "",
        },
        initValues);
    },
	id: 'id',
	name: 'name',
};

export default ResourceCategoryDTO;