// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';


export class AAUserApiService extends BaseApi {

    // post: api/aaUsers/aaUserImporterhandler
    public aaUserImporter(aaUserImporter: FormData): Promise<any> {
        let url = `api/aaUsers/aaUserImporterhandler`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(aaUserImporter, 'post', url, false, false);
    }

    // get: api/aaUsers/aaUserTemplate
    public aaUserTempalte(): Promise<any> {
        let url = `api/aaUsers/aaUserTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new AAUserApiService();
export default service;
