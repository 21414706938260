// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SecondaryCipNumberDTO from './SecondaryCipNumberDTO';
import DistrictDTO from './DistrictDTO';
import SecondaryProgramReviewHistoryDTO from './SecondaryProgramReviewHistoryDTO';
import SecondaryProgramCourseDTO from './SecondaryProgramCourseDTO';
import SecondaryProgramThirdPartyCredentialDTO from './SecondaryProgramThirdPartyCredentialDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SecondaryProgramDTO extends EntitySelectorDTO { 
	id: string | null;
	secondaryProgramId: number;
	secondaryCipNumber: SecondaryCipNumberDTO | null;
	academicYear: number;
	districtId: string | null;
	district: DistrictDTO | null;
	cipNumberId: string | null;
	programStartYear: number | null | null;
	programEndYear: number | null | null;
	secondaryProgramReviewHistories: SecondaryProgramReviewHistoryDTO[] | null;
	secondaryProgramCourses: SecondaryProgramCourseDTO[] | null;
	thirdPartyCredentials: SecondaryProgramThirdPartyCredentialDTO[] | null;
}

const SecondaryProgramDTO = {
    className: 'SecondaryProgramDTO',
    create: (initValues?: Partial<SecondaryProgramDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			secondaryProgramId: 0,
			secondaryCipNumber: null,
			academicYear: 0,
			districtId: '00000000-0000-0000-0000-000000000000',
			district: null,
			cipNumberId: '00000000-0000-0000-0000-000000000000',
			programStartYear: null,
			programEndYear: null,
			secondaryProgramReviewHistories: [],
			secondaryProgramCourses: [],
			thirdPartyCredentials: [],
        },
        initValues);
    },
	id: 'id',
	secondaryProgramId: 'secondaryProgramId',
	secondaryCipNumber: 'secondaryCipNumber',
	academicYear: 'academicYear',
	districtId: 'districtId',
	district: 'district',
	cipNumberId: 'cipNumberId',
	programStartYear: 'programStartYear',
	programEndYear: 'programEndYear',
	secondaryProgramReviewHistories: 'secondaryProgramReviewHistories',
	secondaryProgramCourses: 'secondaryProgramCourses',
	thirdPartyCredentials: 'thirdPartyCredentials',
};

export default SecondaryProgramDTO;