// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// BulkCourseRequestHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from '../models/ResultDTO';

interface Request {
    institutionId: string | null;
    file: any | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            institutionId: '00000000-0000-0000-0000-000000000000',
            file: null,
        },
        initValues);
    },
    institutionId: 'institutionId',
    file: 'file',
}

interface Result extends ResultDTO {
    file: any | null;
    fileData: string | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            file: null,
            fileData: "",
        },
        initValues);
    },
    file: 'file',
    fileData: 'fileData',
}

export { Request, Result };