// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SecondaryProgramReviewHistoryDTO from '../models/SecondaryProgramReviewHistoryDTO';

export class SecondaryProgramHistoryApiService extends BaseApi {

    // post: api/secondaryProgramHistories?secondaryProgramHistoryId=${secondaryProgramHistoryId}&academicYear=${academicYear}
    public getSecondaryProgramTableData(tableRequest: TableRequestDTO, secondaryProgramHistoryId: number, academicYear: number): Promise<TableResponseDTO<SecondaryProgramReviewHistoryDTO>> {
        let url = `api/secondaryProgramHistories`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (secondaryProgramHistoryId != null) {
            url += `${prefix}secondaryProgramHistoryId=${secondaryProgramHistoryId}`;
            prefix = '&';
        }
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SecondaryProgramReviewHistoryDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/secondaryProgramHistories/secondaryProgramHistoryImporter
    public secondaryProgramHisotryImporter(): Promise<any> {
        let url = `api/secondaryProgramHistories/secondaryProgramHistoryImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new SecondaryProgramHistoryApiService();
export default service;
