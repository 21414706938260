// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import APSGeneralEducationDTO from './APSGeneralEducationDTO';
import AwardTypeDTO from './AwardTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AwardTypeAPSGeneralEducationCreditsDTO extends EntitySelectorDTO { 
	id: number;
	apsGeneralEducationId: number;
	apsGeneralEducation: APSGeneralEducationDTO | null;
	awardTypeId: number;
	awardType: AwardTypeDTO | null;
	minimumCredits: number;
}

const AwardTypeAPSGeneralEducationCreditsDTO = {
    className: 'AwardTypeAPSGeneralEducationCreditsDTO',
    create: (initValues?: Partial<AwardTypeAPSGeneralEducationCreditsDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			apsGeneralEducationId: 0,
			apsGeneralEducation: null,
			awardTypeId: 0,
			awardType: null,
			minimumCredits: 0,
        },
        initValues);
    },
	id: 'id',
	apsGeneralEducationId: 'apsGeneralEducationId',
	apsGeneralEducation: 'apsGeneralEducation',
	awardTypeId: 'awardTypeId',
	awardType: 'awardType',
	minimumCredits: 'minimumCredits',
};

export default AwardTypeAPSGeneralEducationCreditsDTO;