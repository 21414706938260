// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import WorkBasedLearningTypeDTO from '../models/WorkBasedLearningTypeDTO';
import * as GetWorkBasedLearningTypeDetailsHandler from '../handlerModels/GetWorkBasedLearningTypeDetailsHandler';
import * as SaveWorkBasedLearningTypeDetailsHandler from '../handlerModels/SaveWorkBasedLearningTypeDetailsHandler';
import * as DeleteWorkBasedLearningTypeHandler from '../handlerModels/DeleteWorkBasedLearningTypeHandler';

export class WorkBasedLearningTypeApiService extends BaseApi {

    // post: api/workBasedLearningType
    public getWorkBasedLearningTypeTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<WorkBasedLearningTypeDTO>> {
        let url = `api/workBasedLearningType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<WorkBasedLearningTypeDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/workBasedLearningType/export
    public getWorkBasedLearningTypeDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/workBasedLearningType/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // post: api/workBasedLearningType/${id}
    public getWorkBasedLearningTypeDetails(id: number): Promise<GetWorkBasedLearningTypeDetailsHandler.Result> {
        let url = `api/workBasedLearningType/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetWorkBasedLearningTypeDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/workBasedLearningType/save
    public saveWorkBasedLearningTypeDetails(saveWorkBasedLearningTypeDetails: SaveWorkBasedLearningTypeDetailsHandler.Request): Promise<SaveWorkBasedLearningTypeDetailsHandler.Result> {
        let url = `api/workBasedLearningType/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveWorkBasedLearningTypeDetailsHandler.Result>(saveWorkBasedLearningTypeDetails, 'post', url, true, false);
    }

    // post: api/workBasedLearningType/deleteWorkBasedLearningType
    public deleteWorkBasedLearningType(deleteWorkBasedLearningType: DeleteWorkBasedLearningTypeHandler.Request): Promise<DeleteWorkBasedLearningTypeHandler.Result> {
        let url = `api/workBasedLearningType/deleteWorkBasedLearningType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteWorkBasedLearningTypeHandler.Result>(deleteWorkBasedLearningType, 'post', url, true, false);
    }
}
var service = new WorkBasedLearningTypeApiService();
export default service;
