// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ChangeRequestDTO from './ChangeRequestDTO';
import UserDTO from './UserDTO';
import RoleDTO from './RoleDTO';
import ApprovalStatusTypeDTO from './ApprovalStatusTypeDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ChangeRequestApprovalDTO extends EntitySelectorDTO { 
	id: string | null;
	changeRequestId: string | null;
	changeRequest: ChangeRequestDTO | null;
	userId: string | null | null;
	user: UserDTO | null;
	changeRequestTypeId: number;
	stepNumber: number;
	roleId: string | null;
	role: RoleDTO | null;
	approvalStatusTypeId: number;
	approvalStatusType: ApprovalStatusTypeDTO | null;
	activeOn: moment.Moment | string;
	expiresOn: moment.Moment | string | null;
	reviewed: moment.Moment | string | null;
}

const ChangeRequestApprovalDTO = {
    className: 'ChangeRequestApprovalDTO',
    create: (initValues?: Partial<ChangeRequestApprovalDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			changeRequestId: '00000000-0000-0000-0000-000000000000',
			changeRequest: null,
			userId: null,
			user: null,
			changeRequestTypeId: 0,
			stepNumber: 0,
			roleId: '00000000-0000-0000-0000-000000000000',
			role: null,
			approvalStatusTypeId: 0,
			approvalStatusType: null,
			activeOn: new Date(0).toISOString(),
			expiresOn: null,
			reviewed: null,
        },
        initValues);
    },
	id: 'id',
	changeRequestId: 'changeRequestId',
	changeRequest: 'changeRequest',
	userId: 'userId',
	user: 'user',
	changeRequestTypeId: 'changeRequestTypeId',
	stepNumber: 'stepNumber',
	roleId: 'roleId',
	role: 'role',
	approvalStatusTypeId: 'approvalStatusTypeId',
	approvalStatusType: 'approvalStatusType',
	activeOn: 'activeOn',
	expiresOn: 'expiresOn',
	reviewed: 'reviewed',
};

export default ChangeRequestApprovalDTO;