// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import InstitutionDTO from './InstitutionDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface RegionalPlanningPartnerDTO extends EntitySelectorDTO { 
	id: string | null;
	academicYear: number;
	regionalPlanningPartnerIdentifier: number;
	name: string | null;
	institutionId: string | null;
	institution: InstitutionDTO | null;
	display: string | null;
}

const RegionalPlanningPartnerDTO = {
    className: 'RegionalPlanningPartnerDTO',
    create: (initValues?: Partial<RegionalPlanningPartnerDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			academicYear: 0,
			regionalPlanningPartnerIdentifier: 0,
			name: "",
			institutionId: '00000000-0000-0000-0000-000000000000',
			institution: null,
			display: "",
        },
        initValues);
    },
	id: 'id',
	academicYear: 'academicYear',
	regionalPlanningPartnerIdentifier: 'regionalPlanningPartnerIdentifier',
	name: 'name',
	institutionId: 'institutionId',
	institution: 'institution',
	display: 'display',
};

export default RegionalPlanningPartnerDTO;