// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface AreaEducationAgencyDTO extends EntitySelectorDTO { 
	id: string | null;
	areaEducationAgencyIdentifier: number;
	academicYear: number;
	name: string | null;
	display: string | null;
}

const AreaEducationAgencyDTO = {
    className: 'AreaEducationAgencyDTO',
    create: (initValues?: Partial<AreaEducationAgencyDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			areaEducationAgencyIdentifier: 0,
			academicYear: 0,
			name: "",
			display: "",
        },
        initValues);
    },
	id: 'id',
	areaEducationAgencyIdentifier: 'areaEducationAgencyIdentifier',
	academicYear: 'academicYear',
	name: 'name',
	display: 'display',
};

export default AreaEducationAgencyDTO;