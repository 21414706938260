// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveWorkflowDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ChangeRequestTypeApprovalFlowDTO from '../models/ChangeRequestTypeApprovalFlowDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    changeRequestTypeApprovalFlow: ChangeRequestTypeApprovalFlowDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            changeRequestTypeApprovalFlow: null,
        },
        initValues);
    },
    changeRequestTypeApprovalFlow: 'changeRequestTypeApprovalFlow',
}

interface Result extends ResultDTO {
    changeRequestTypeApprovalFlow: ChangeRequestTypeApprovalFlowDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            changeRequestTypeApprovalFlow: null,
        },
        initValues);
    },
    changeRequestTypeApprovalFlow: 'changeRequestTypeApprovalFlow',
}

export { Request, Result };