// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import TransferMajorDisciplineFrameworkElementDTO from './TransferMajorDisciplineFrameworkElementDTO';
import CourseDTO from './CourseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface TransferMajorDisciplineFrameworkElementCourseDTO extends EntitySelectorDTO { 
	id: string | null;
	transferMajorDisciplineFrameworkElementId: number;
	courseId: string | null;
	transferMajorDisciplineFramework: TransferMajorDisciplineFrameworkElementDTO | null;
	course: CourseDTO | null;
}

const TransferMajorDisciplineFrameworkElementCourseDTO = {
    className: 'TransferMajorDisciplineFrameworkElementCourseDTO',
    create: (initValues?: Partial<TransferMajorDisciplineFrameworkElementCourseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			transferMajorDisciplineFrameworkElementId: 0,
			courseId: '00000000-0000-0000-0000-000000000000',
			transferMajorDisciplineFramework: null,
			course: null,
        },
        initValues);
    },
	id: 'id',
	transferMajorDisciplineFrameworkElementId: 'transferMajorDisciplineFrameworkElementId',
	courseId: 'courseId',
	transferMajorDisciplineFramework: 'transferMajorDisciplineFramework',
	course: 'course',
};

export default TransferMajorDisciplineFrameworkElementCourseDTO;