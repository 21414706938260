// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ContactTypeDTO extends EntitySelectorDTO { 
	id: number;
	displayOrder: number;
	name: string | null;
}

const ContactTypeDTO = {
    className: 'ContactTypeDTO',
    create: (initValues?: Partial<ContactTypeDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: 0,
			displayOrder: 0,
			name: "",
        },
        initValues);
    },
	id: 'id',
	displayOrder: 'displayOrder',
	name: 'name',
};

export default ContactTypeDTO;