// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ScenarioReportDataTableDTO extends EntitySelectorDTO { 
	rpp: string | null;
	district: string | null;
	cipNumber: string | null;
	program: string | null;
	scenario: string | null;
	localCourseNumber: string | null;
	courseName: string | null;
	scedId: string | null;
	scedGorC: string | null;
	scedUnits: string | null;
	communityCollege: string | null;
	ccn: string | null;
}

const ScenarioReportDataTableDTO = {
    className: 'ScenarioReportDataTableDTO',
    create: (initValues?: Partial<ScenarioReportDataTableDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			rpp: "",
			district: "",
			cipNumber: "",
			program: "",
			scenario: "",
			localCourseNumber: "",
			courseName: "",
			scedId: "",
			scedGorC: "",
			scedUnits: "",
			communityCollege: "",
			ccn: "",
        },
        initValues);
    },
	rpp: 'rpp',
	district: 'district',
	cipNumber: 'cipNumber',
	program: 'program',
	scenario: 'scenario',
	localCourseNumber: 'localCourseNumber',
	courseName: 'courseName',
	scedId: 'scedId',
	scedGorC: 'scedGorC',
	scedUnits: 'scedUnits',
	communityCollege: 'communityCollege',
	ccn: 'ccn',
};

export default ScenarioReportDataTableDTO;