// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ChangeRequestTypeApprovalFlowDTO from './ChangeRequestTypeApprovalFlowDTO';
import ChangeRequestTypeApprovalFlowStepApprovalRoleDTO from './ChangeRequestTypeApprovalFlowStepApprovalRoleDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ChangeRequestTypeApprovalFlowStepDTO extends EntitySelectorDTO { 
	changeRequestTypeId: number;
	stepNumber: number;
	changeRequestApprovalFlow: ChangeRequestTypeApprovalFlowDTO | null;
	requireAll: boolean;
	changeRequestTypeApprovalFlowStepApprovalRoles: ChangeRequestTypeApprovalFlowStepApprovalRoleDTO[] | null;
}

const ChangeRequestTypeApprovalFlowStepDTO = {
    className: 'ChangeRequestTypeApprovalFlowStepDTO',
    create: (initValues?: Partial<ChangeRequestTypeApprovalFlowStepDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			changeRequestTypeId: 0,
			stepNumber: 0,
			changeRequestApprovalFlow: null,
			requireAll: false,
			changeRequestTypeApprovalFlowStepApprovalRoles: [],
        },
        initValues);
    },
	changeRequestTypeId: 'changeRequestTypeId',
	stepNumber: 'stepNumber',
	changeRequestApprovalFlow: 'changeRequestApprovalFlow',
	requireAll: 'requireAll',
	changeRequestTypeApprovalFlowStepApprovalRoles: 'changeRequestTypeApprovalFlowStepApprovalRoles',
};

export default ChangeRequestTypeApprovalFlowStepDTO;