// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as GetSelfStudyResponseByTypeHandler from '../handlerModels/GetSelfStudyResponseByTypeHandler';

export class SelfStudyResponseApiService extends BaseApi {

    // post: api/selfStudyResponses/getResponseByType
    public getSelfStudyResponseByType(getSelfStudyResponseByType: GetSelfStudyResponseByTypeHandler.Request): Promise<GetSelfStudyResponseByTypeHandler.Result> {
        let url = `api/selfStudyResponses/getResponseByType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyResponseByTypeHandler.Result>(getSelfStudyResponseByType, 'post', url, true, false);
    }
}
var service = new SelfStudyResponseApiService();
export default service;
