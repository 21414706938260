// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import AreaEducationAgencyDTO from '../models/AreaEducationAgencyDTO';
import * as GetAreaEducationAgencyDetailsHandler from '../handlerModels/GetAreaEducationAgencyDetailsHandler';
import * as SaveAreaEducationAgencyDetailsHandler from '../handlerModels/SaveAreaEducationAgencyDetailsHandler';
import * as DeleteAreaEducationAgencyHandler from '../handlerModels/DeleteAreaEducationAgencyHandler';
import * as GetAreaEducationAgenciesHandler from '../handlerModels/GetAreaEducationAgenciesHandler';

export class AreaEducationAgencyApiService extends BaseApi {

    // post: api/areaEducationAgencys?academicYearId=${academicYearId}
    public getAreaEducationAgencyTableData(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<AreaEducationAgencyDTO>> {
        let url = `api/areaEducationAgencys`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<AreaEducationAgencyDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/areaEducationAgencys/get
    public getAreaEducationAgencyDetails(getAreaEducationAgencyDetails: GetAreaEducationAgencyDetailsHandler.Request): Promise<GetAreaEducationAgencyDetailsHandler.Result> {
        let url = `api/areaEducationAgencys/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAreaEducationAgencyDetailsHandler.Result>(getAreaEducationAgencyDetails, 'post', url, true, false);
    }

    // post: api/areaEducationAgencys/save
    public saveAreaEducationAgencyDetails(saveAreaEducationAgencyDetails: SaveAreaEducationAgencyDetailsHandler.Request): Promise<SaveAreaEducationAgencyDetailsHandler.Result> {
        let url = `api/areaEducationAgencys/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveAreaEducationAgencyDetailsHandler.Result>(saveAreaEducationAgencyDetails, 'post', url, true, false);
    }

    // post: api/areaEducationAgencys/deleteAreaEducationAgency
    public deleteAreaEducationAgency(deleteAreaEducationAgency: DeleteAreaEducationAgencyHandler.Request): Promise<DeleteAreaEducationAgencyHandler.Result> {
        let url = `api/areaEducationAgencys/deleteAreaEducationAgency`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteAreaEducationAgencyHandler.Result>(deleteAreaEducationAgency, 'post', url, true, false);
    }

    // post: api/areaEducationAgencys/areaEducationAgencys/${academicYear}
    public getAreaEducationAgencies(academicYear: number): Promise<GetAreaEducationAgenciesHandler.Result> {
        let url = `api/areaEducationAgencys/areaEducationAgencys/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetAreaEducationAgenciesHandler.Result>(null, 'post', url, true, false);
    }
}
var service = new AreaEducationAgencyApiService();
export default service;
