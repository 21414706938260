// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ServiceAreaDTO from '../models/ServiceAreaDTO';
import * as GetServiceAreaDetailsHandler from '../handlerModels/GetServiceAreaDetailsHandler';
import * as SaveServiceAreaDetailsHandler from '../handlerModels/SaveServiceAreaDetailsHandler';
import * as DeleteServiceAreaHandler from '../handlerModels/DeleteServiceAreaHandler';
import * as GetServiceAreasHandler from '../handlerModels/GetServiceAreasHandler';

export class ServiceAreaApiService extends BaseApi {

    // post: api/serviceAreas?academicYearId=${academicYearId}
    public getServiceAreaTableData(tableRequest: TableRequestDTO, academicYearId: number): Promise<TableResponseDTO<ServiceAreaDTO>> {
        let url = `api/serviceAreas`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ServiceAreaDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/serviceAreas/${encodeURIComponent(id)}
    public getServiceAreaDetails(id: string): Promise<GetServiceAreaDetailsHandler.Result> {
        let url = `api/serviceAreas/${encodeURIComponent(id)}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetServiceAreaDetailsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/serviceAreas/save
    public saveServiceAreaDetails(saveServiceAreaDetails: SaveServiceAreaDetailsHandler.Request): Promise<SaveServiceAreaDetailsHandler.Result> {
        let url = `api/serviceAreas/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveServiceAreaDetailsHandler.Result>(saveServiceAreaDetails, 'post', url, true, false);
    }

    // post: api/serviceAreas/delete
    public deleteServiceArea(deleteServiceArea: DeleteServiceAreaHandler.Request): Promise<DeleteServiceAreaHandler.Result> {
        let url = `api/serviceAreas/delete`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteServiceAreaHandler.Result>(deleteServiceArea, 'post', url, true, false);
    }

    // post: api/serviceAreas/list?academicYearId=${academicYearId}
    public getServiceAreas(academicYearId: number): Promise<GetServiceAreasHandler.Result> {
        let url = `api/serviceAreas/list`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetServiceAreasHandler.Result>(null, 'post', url, true, false);
    }
}
var service = new ServiceAreaApiService();
export default service;
