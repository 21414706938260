// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ResourceCategoryDTO from './ResourceCategoryDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ResourceLinkDTO extends EntitySelectorDTO { 
	id: string | null;
	title: string | null;
	url: string | null;
	resourceCategory: ResourceCategoryDTO | null;
	categoryTitle: string | null;
	description: string | null;
}

const ResourceLinkDTO = {
    className: 'ResourceLinkDTO',
    create: (initValues?: Partial<ResourceLinkDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			title: "",
			url: "",
			resourceCategory: null,
			categoryTitle: "",
			description: "",
        },
        initValues);
    },
	id: 'id',
	title: 'title',
	url: 'url',
	resourceCategory: 'resourceCategory',
	categoryTitle: 'categoryTitle',
	description: 'description',
};

export default ResourceLinkDTO;