// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SecondaryProgramCourseDTO from '../models/SecondaryProgramCourseDTO';
import * as AcademicYearHasSecondaryProgramCoursesHandler from '../handlerModels/AcademicYearHasSecondaryProgramCoursesHandler';
import * as GetSecondaryProgramCoursesHandler from '../handlerModels/GetSecondaryProgramCoursesHandler';
import * as GetSecondaryProgramCourseDetailsHandler from '../handlerModels/GetSecondaryProgramCourseDetailsHandler';
import * as SaveSecondaryProgramCourseHandler from '../handlerModels/SaveSecondaryProgramCourseHandler';
import * as DeleteSecondaryProgramCourseHandler from '../handlerModels/DeleteSecondaryProgramCourseHandler';

export class SecondaryProgramCourseApiService extends BaseApi {

    // post: api/secondaryProgramCourses/hasProgramCourses/${academicYear}
    public academicYearHasProgramCourses(academicYear: number): Promise<AcademicYearHasSecondaryProgramCoursesHandler.Result> {
        let url = `api/secondaryProgramCourses/hasProgramCourses/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AcademicYearHasSecondaryProgramCoursesHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/secondaryProgramCourses?academicYear=${academicYear}
    public getSecondaryProgramCourseTableData(tableRequest: TableRequestDTO, academicYear: number): Promise<TableResponseDTO<SecondaryProgramCourseDTO>> {
        let url = `api/secondaryProgramCourses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SecondaryProgramCourseDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/secondaryProgramCourses/secondaryProgramCourses
    public getSecondaryProgramCourses(getSecondaryProgramCourses: GetSecondaryProgramCoursesHandler.Request): Promise<GetSecondaryProgramCoursesHandler.Result> {
        let url = `api/secondaryProgramCourses/secondaryProgramCourses`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryProgramCoursesHandler.Result>(getSecondaryProgramCourses, 'post', url, true, false);
    }

    // post: api/secondaryProgramCourses/details
    public getSecondaryProgramCourseDetails(getSecondaryProgramCourseDetails: GetSecondaryProgramCourseDetailsHandler.Request): Promise<GetSecondaryProgramCourseDetailsHandler.Result> {
        let url = `api/secondaryProgramCourses/details`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryProgramCourseDetailsHandler.Result>(getSecondaryProgramCourseDetails, 'post', url, true, false);
    }

    // post: api/secondaryProgramCourses/save
    public addSecondaryProgramCourseDetails(saveSecondaryProgramCourse: SaveSecondaryProgramCourseHandler.Request): Promise<SaveSecondaryProgramCourseHandler.Result> {
        let url = `api/secondaryProgramCourses/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSecondaryProgramCourseHandler.Result>(saveSecondaryProgramCourse, 'post', url, true, false);
    }

    // post: api/secondaryProgramCourses/deleteSecondaryProgramCourse
    public deleteSecondaryProgramCourse(deleteSecondaryProgramCourse: DeleteSecondaryProgramCourseHandler.Request): Promise<DeleteSecondaryProgramCourseHandler.Result> {
        let url = `api/secondaryProgramCourses/deleteSecondaryProgramCourse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteSecondaryProgramCourseHandler.Result>(deleteSecondaryProgramCourse, 'post', url, true, false);
    }

    // post: api/secondaryProgramCourses/secondaryProgramCourseImporter
    public secondaryProgramCourseImporter(secondaryProgramCourseImporter: FormData): Promise<any> {
        let url = `api/secondaryProgramCourses/secondaryProgramCourseImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(secondaryProgramCourseImporter, 'post', url, false, false);
    }

    // get: api/secondaryProgramCourses/secondaryProgramCourseTemplate
    public secondaryProgramCourseTemplate(): Promise<any> {
        let url = `api/secondaryProgramCourses/secondaryProgramCourseTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new SecondaryProgramCourseApiService();
export default service;
