// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import UserDTO from './UserDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface UserRegionalPlanningPartnerAssignmentDTO extends EntitySelectorDTO { 
	id: string | null;
	regionalPlanningPartnerIdentifier: number;
	regionalPlanningPartnerName: string | null;
	userId: string | null;
	user: UserDTO | null;
	email: string | null;
	firstName: string | null;
	lastName: string | null;
	roles: string[];
	roleIds: string[];
}

const UserRegionalPlanningPartnerAssignmentDTO = {
    className: 'UserRegionalPlanningPartnerAssignmentDTO',
    create: (initValues?: Partial<UserRegionalPlanningPartnerAssignmentDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			regionalPlanningPartnerIdentifier: 0,
			regionalPlanningPartnerName: "",
			userId: '00000000-0000-0000-0000-000000000000',
			user: null,
			email: "",
			firstName: "",
			lastName: "",
			roles: [],
			roleIds: [],
        },
        initValues);
    },
	id: 'id',
	regionalPlanningPartnerIdentifier: 'regionalPlanningPartnerIdentifier',
	regionalPlanningPartnerName: 'regionalPlanningPartnerName',
	userId: 'userId',
	user: 'user',
	email: 'email',
	firstName: 'firstName',
	lastName: 'lastName',
	roles: 'roles',
	roleIds: 'roleIds',
};

export default UserRegionalPlanningPartnerAssignmentDTO;