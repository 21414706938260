// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SecondaryProgramScenarioDTO extends EntitySelectorDTO { 
	id: string | null;
	secondaryProgramScenarioId: number;
	academicYear: number;
	title: string | null;
	description: string | null;
}

const SecondaryProgramScenarioDTO = {
    className: 'SecondaryProgramScenarioDTO',
    create: (initValues?: Partial<SecondaryProgramScenarioDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			secondaryProgramScenarioId: 0,
			academicYear: 0,
			title: "",
			description: "",
        },
        initValues);
    },
	id: 'id',
	secondaryProgramScenarioId: 'secondaryProgramScenarioId',
	academicYear: 'academicYear',
	title: 'title',
	description: 'description',
};

export default SecondaryProgramScenarioDTO;