// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import SelfStudyResponseDTO from './SelfStudyResponseDTO';
import SelfStudySectionTemplateDTO from './SelfStudySectionTemplateDTO';
import SelfStudySectionComponentResponseDTO from './SelfStudySectionComponentResponseDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface SelfStudySectionResponseDTO extends EntitySelectorDTO { 
	id: string | null;
	selfStudyResponseId: string | null;
	selfStudyResponse: SelfStudyResponseDTO | null;
	selfStudySectionTemplateId: string | null;
	selfStudySectionTemplate: SelfStudySectionTemplateDTO | null;
	sectionComments: string | null;
	selfStudySectionComponentResponses: SelfStudySectionComponentResponseDTO[] | null;
}

const SelfStudySectionResponseDTO = {
    className: 'SelfStudySectionResponseDTO',
    create: (initValues?: Partial<SelfStudySectionResponseDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			selfStudyResponseId: '00000000-0000-0000-0000-000000000000',
			selfStudyResponse: null,
			selfStudySectionTemplateId: '00000000-0000-0000-0000-000000000000',
			selfStudySectionTemplate: null,
			sectionComments: "",
			selfStudySectionComponentResponses: [],
        },
        initValues);
    },
	id: 'id',
	selfStudyResponseId: 'selfStudyResponseId',
	selfStudyResponse: 'selfStudyResponse',
	selfStudySectionTemplateId: 'selfStudySectionTemplateId',
	selfStudySectionTemplate: 'selfStudySectionTemplate',
	sectionComments: 'sectionComments',
	selfStudySectionComponentResponses: 'selfStudySectionComponentResponses',
};

export default SelfStudySectionResponseDTO;