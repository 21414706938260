// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import ExternalInstitutionDTO from './ExternalInstitutionDTO';
import NewProgramChangeRequestDTO from './NewProgramChangeRequestDTO';
import AzureBlobFileDTO from './AzureBlobFileDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NewProgramChangeRequestInstitutionLinkageDTO extends EntitySelectorDTO { 
	id: string | null;
	externalInstitutionId: number;
	externalInstitution: ExternalInstitutionDTO | null;
	newProgramChangeRequestId: string | null;
	newProgramChangeRequest: NewProgramChangeRequestDTO | null;
	description: string | null;
	azureBlobFileId: string | null | null;
	azureBlobFile: AzureBlobFileDTO | null;
}

const NewProgramChangeRequestInstitutionLinkageDTO = {
    className: 'NewProgramChangeRequestInstitutionLinkageDTO',
    create: (initValues?: Partial<NewProgramChangeRequestInstitutionLinkageDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			externalInstitutionId: 0,
			externalInstitution: null,
			newProgramChangeRequestId: '00000000-0000-0000-0000-000000000000',
			newProgramChangeRequest: null,
			description: "",
			azureBlobFileId: null,
			azureBlobFile: null,
        },
        initValues);
    },
	id: 'id',
	externalInstitutionId: 'externalInstitutionId',
	externalInstitution: 'externalInstitution',
	newProgramChangeRequestId: 'newProgramChangeRequestId',
	newProgramChangeRequest: 'newProgramChangeRequest',
	description: 'description',
	azureBlobFileId: 'azureBlobFileId',
	azureBlobFile: 'azureBlobFile',
};

export default NewProgramChangeRequestInstitutionLinkageDTO;