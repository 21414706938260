// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveRacialEthnicGroupDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import RacialEthnicGroupDTO from '../models/RacialEthnicGroupDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    racialEthnicGroupId: number;
    racialEthnicGroup: RacialEthnicGroupDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            racialEthnicGroupId: 0,
            racialEthnicGroup: null,
        },
        initValues);
    },
    racialEthnicGroupId: 'racialEthnicGroupId',
    racialEthnicGroup: 'racialEthnicGroup',
}

interface Result extends ResultDTO {
    racialEthnicGroup: RacialEthnicGroupDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            racialEthnicGroup: null,
        },
        initValues);
    },
    racialEthnicGroup: 'racialEthnicGroup',
}

export { Request, Result };