// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import * as SaveSelfStudyTemplateHandler from '../handlerModels/SaveSelfStudyTemplateHandler';
import * as GetSelfStudyConfigurationHandler from '../handlerModels/GetSelfStudyConfigurationHandler';
import * as GetSelfStudyTemplateSectionsHandler from '../handlerModels/GetSelfStudyTemplateSectionsHandler';
import * as GetSelfStudySectionTemplateBySectionOrderHandler from '../handlerModels/GetSelfStudySectionTemplateBySectionOrderHandler';
import * as GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler from '../handlerModels/GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler';

export class SelfStudyTemplateApiService extends BaseApi {

    // post: api/selfStudyTemplates/saveTemplateDetails
    public saveSelfStudyTemplate(saveSelfStudyTemplate: SaveSelfStudyTemplateHandler.Request): Promise<SaveSelfStudyTemplateHandler.Result> {
        let url = `api/selfStudyTemplates/saveTemplateDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSelfStudyTemplateHandler.Result>(saveSelfStudyTemplate, 'post', url, true, false);
    }

    // get: api/selfStudyTemplates/${academicYear}
    public getSelfStudy(academicYear: number): Promise<GetSelfStudyConfigurationHandler.Result> {
        let url = `api/selfStudyTemplates/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyConfigurationHandler.Result>(null, 'get', url, true, false);
    }

    // get: api/selfStudyTemplates/getSelfStudySectionTemplates/${academicYear}
    public getSelfStudySectionTemplates(academicYear: number): Promise<GetSelfStudyTemplateSectionsHandler.Result> {
        let url = `api/selfStudyTemplates/getSelfStudySectionTemplates/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudyTemplateSectionsHandler.Result>(null, 'get', url, true, false);
    }

    // get: api/selfStudyTemplates/getSelfStudySectionTemplate?academicYearId=${academicYearId}&sectionIndex=${sectionIndex}
    public getSelfStudySectionTemplateBySectionOrder(academicYearId: number, sectionIndex: number): Promise<GetSelfStudySectionTemplateBySectionOrderHandler.Result> {
        let url = `api/selfStudyTemplates/getSelfStudySectionTemplate`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }
        if (sectionIndex != null) {
            url += `${prefix}sectionIndex=${sectionIndex}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudySectionTemplateBySectionOrderHandler.Result>(null, 'get', url, true, false);
    }

    // post: api/selfStudyTemplates/getSelfStudySectionComponentTemplate
    public getSelfStudySectionComponentTemplateBySectionOrder(getSelfStudySectionTemplateBySectionOrderAndComponentOrder: GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Request): Promise<GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result> {
        let url = `api/selfStudyTemplates/getSelfStudySectionComponentTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result>(getSelfStudySectionTemplateBySectionOrderAndComponentOrder, 'post', url, true, false);
    }
}
var service = new SelfStudyTemplateApiService();
export default service;
