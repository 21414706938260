// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveWorkBasedLearningTypeDetailsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import WorkBasedLearningTypeDTO from '../models/WorkBasedLearningTypeDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    workBasedLearningTypeId: number;
    workBasedLearningType: WorkBasedLearningTypeDTO | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            workBasedLearningTypeId: 0,
            workBasedLearningType: null,
        },
        initValues);
    },
    workBasedLearningTypeId: 'workBasedLearningTypeId',
    workBasedLearningType: 'workBasedLearningType',
}

interface Result extends ResultDTO {
    workBasedLearningType: WorkBasedLearningTypeDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            workBasedLearningType: null,
        },
        initValues);
    },
    workBasedLearningType: 'workBasedLearningType',
}

export { Request, Result };