// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface NoticeOfIntentChangeRequestWorkForceLinkDTO extends EntitySelectorDTO { 
	id: string | null;
	title: string | null;
	url: string | null;
}

const NoticeOfIntentChangeRequestWorkForceLinkDTO = {
    className: 'NoticeOfIntentChangeRequestWorkForceLinkDTO',
    create: (initValues?: Partial<NoticeOfIntentChangeRequestWorkForceLinkDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			title: "",
			url: "",
        },
        initValues);
    },
	id: 'id',
	title: 'title',
	url: 'url',
};

export default NoticeOfIntentChangeRequestWorkForceLinkDTO;