// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import UserRegionalPlanningPartnerAssignmentDTO from '../models/UserRegionalPlanningPartnerAssignmentDTO';
import * as AddUserToRegionalPlanningPartnerHandler from '../handlerModels/AddUserToRegionalPlanningPartnerHandler';
import * as AddUserRegionalPlanningPartnerAssignmentHandler from '../handlerModels/AddUserRegionalPlanningPartnerAssignmentHandler';
import * as EditUserRegionalPlanningPartnerAssignmentRolesHandler from '../handlerModels/EditUserRegionalPlanningPartnerAssignmentRolesHandler';
import * as UnassignUserRegionalPlanningPartnerAssignmentHandler from '../handlerModels/UnassignUserRegionalPlanningPartnerAssignmentHandler';

export class UserRegionalPlanningPartnerAssignmentApiService extends BaseApi {

    // post: api/userRegionalPlanningPartnerAssignment?userId=${encodeURIComponent(userId)}&regionalPlanningPartner=${regionalPlanningPartner}
    public getUserRegionalPlanningPartnerAssignmentTableData(tableRequest: TableRequestDTO, userId: string, regionalPlanningPartner: number): Promise<TableResponseDTO<UserRegionalPlanningPartnerAssignmentDTO>> {
        let url = `api/userRegionalPlanningPartnerAssignment`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${encodeURIComponent(userId)}`;
            prefix = '&';
        }
        if (regionalPlanningPartner != null) {
            url += `${prefix}regionalPlanningPartner=${regionalPlanningPartner}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserRegionalPlanningPartnerAssignmentDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/userRegionalPlanningPartnerAssignment/usersInRPP?rppId=${rppId}
    public getUsersInRegionalPlanningPartnerAssignmentTableData(tableRequest: TableRequestDTO, rppId: number): Promise<TableResponseDTO<UserRegionalPlanningPartnerAssignmentDTO>> {
        let url = `api/userRegionalPlanningPartnerAssignment/usersInRPP`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (rppId != null) {
            url += `${prefix}rppId=${rppId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserRegionalPlanningPartnerAssignmentDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/userRegionalPlanningPartnerAssignment/addUser
    public addRPPUser(addUserToRegionalPlanningPartner: AddUserToRegionalPlanningPartnerHandler.Request): Promise<AddUserToRegionalPlanningPartnerHandler.Result> {
        let url = `api/userRegionalPlanningPartnerAssignment/addUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddUserToRegionalPlanningPartnerHandler.Result>(addUserToRegionalPlanningPartner, 'post', url, true, false);
    }

    // post: api/userRegionalPlanningPartnerAssignment/save
    public addRegionalPartnerPlanningUser(addUserRegionalPlanningPartnerAssignment: AddUserRegionalPlanningPartnerAssignmentHandler.Request): Promise<AddUserRegionalPlanningPartnerAssignmentHandler.Result> {
        let url = `api/userRegionalPlanningPartnerAssignment/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddUserRegionalPlanningPartnerAssignmentHandler.Result>(addUserRegionalPlanningPartnerAssignment, 'post', url, true, false);
    }

    // post: api/userRegionalPlanningPartnerAssignment/editRoles
    public editUserRegionalPlanningPartnerAssignmentRoles(editUserRegionalPlanningPartnerAssignmentRoles: EditUserRegionalPlanningPartnerAssignmentRolesHandler.Request): Promise<EditUserRegionalPlanningPartnerAssignmentRolesHandler.Result> {
        let url = `api/userRegionalPlanningPartnerAssignment/editRoles`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, EditUserRegionalPlanningPartnerAssignmentRolesHandler.Result>(editUserRegionalPlanningPartnerAssignmentRoles, 'post', url, true, false);
    }

    // post: api/userRegionalPlanningPartnerAssignment/unassign
    public unassignRegionalPartnerPlanningUser(unassignUserRegionalPlanningPartnerAssignment: UnassignUserRegionalPlanningPartnerAssignmentHandler.Request): Promise<UnassignUserRegionalPlanningPartnerAssignmentHandler.Result> {
        let url = `api/userRegionalPlanningPartnerAssignment/unassign`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UnassignUserRegionalPlanningPartnerAssignmentHandler.Result>(unassignUserRegionalPlanningPartnerAssignment, 'post', url, true, false);
    }
}
var service = new UserRegionalPlanningPartnerAssignmentApiService();
export default service;
