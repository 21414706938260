// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!!!!! Do not modify this file or change will probably be lost !!!!!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import Validator from './Validator';
import MinMaxDTO from './MinMaxDTO';
import UserDTO from './UserDTO';
import EntitySelectorDTO from './EntitySelectorDTO';

interface ServiceAreaAssignmentDTO extends EntitySelectorDTO { 
	id: string | null;
	serviceAreaIdentifier: number;
	userId: string | null;
	user: UserDTO | null;
}

const ServiceAreaAssignmentDTO = {
    className: 'ServiceAreaAssignmentDTO',
    create: (initValues?: Partial<ServiceAreaAssignmentDTO> | Record<string, unknown> | null) => {
        return Object.assign(EntitySelectorDTO.create(),
        {
			id: '00000000-0000-0000-0000-000000000000',
			serviceAreaIdentifier: 0,
			userId: '00000000-0000-0000-0000-000000000000',
			user: null,
        },
        initValues);
    },
	id: 'id',
	serviceAreaIdentifier: 'serviceAreaIdentifier',
	userId: 'userId',
	user: 'user',
};

export default ServiceAreaAssignmentDTO;