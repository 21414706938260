// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveResourceFileDetailHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResourceFileDTO from '../models/ResourceFileDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    resourceFileId: string | null;
    additionalFiles: any[] | null;
    removedFiles: string[];
    title: string | null;
    category: string | null;
    description: string | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            resourceFileId: '00000000-0000-0000-0000-000000000000',
            additionalFiles: [],
            removedFiles: [],
            title: "",
            category: "",
            description: "",
        },
        initValues);
    },
    resourceFileId: 'resourceFileId',
    additionalFiles: 'additionalFiles',
    removedFiles: 'removedFiles',
    title: 'title',
    category: 'category',
    description: 'description',
}

interface Result extends ResultDTO {
    resourceFile: ResourceFileDTO | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            resourceFile: null,
        },
        initValues);
    },
    resourceFile: 'resourceFile',
}

export { Request, Result };