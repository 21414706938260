// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetSecondaryProgramAttachmentsHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SecondaryProgramAttachmentDTO from '../models/SecondaryProgramAttachmentDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYear: number | null | null;
    secondaryProgramId: number | null | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYear: null,
            secondaryProgramId: null,
        },
        initValues);
    },
    academicYear: 'academicYear',
    secondaryProgramId: 'secondaryProgramId',
}

interface Result extends ResultDTO {
    secondaryProgramAttachments: SecondaryProgramAttachmentDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            secondaryProgramAttachments: [],
        },
        initValues);
    },
    secondaryProgramAttachments: 'secondaryProgramAttachments',
}

export { Request, Result };