// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import SecondaryCourseDTO from '../models/SecondaryCourseDTO';
import * as GetSecondaryCourseDetailsHandler from '../handlerModels/GetSecondaryCourseDetailsHandler';
import * as AcademicYearHasCoursesHandler from '../handlerModels/AcademicYearHasCoursesHandler';
import * as SaveSecondaryCourseDetailsHandler from '../handlerModels/SaveSecondaryCourseDetailsHandler';
import * as DeleteSecondaryCourseHandler from '../handlerModels/DeleteSecondaryCourseHandler';
import * as SearchForSecondaryCoursesHandler from '../handlerModels/SearchForSecondaryCoursesHandler';
import * as GetSecondaryCoursesHandler from '../handlerModels/GetSecondaryCoursesHandler';

export class SecondaryCourseApiService extends BaseApi {

    // post: api/secondaryCourses?academicYearId=${academicYearId}&programId=${encodeURIComponent(programId)}&isPublic=${isPublic}
    public getSecondaryCourseTableData(tableRequest: TableRequestDTO, academicYearId: number, programId: string | null, isPublic: boolean): Promise<TableResponseDTO<SecondaryCourseDTO>> {
        let url = `api/secondaryCourses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYearId != null) {
            url += `${prefix}academicYearId=${academicYearId}`;
            prefix = '&';
        }
        if (programId != null) {
            url += `${prefix}programId=${programId}`;
            prefix = '&';
        }
        if (isPublic != null) {
            url += `${prefix}isPublic=${isPublic}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<SecondaryCourseDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/secondaryCourses/get
    public getSecondaryCourseDetails(getSecondaryCourseDetails: GetSecondaryCourseDetailsHandler.Request): Promise<GetSecondaryCourseDetailsHandler.Result> {
        let url = `api/secondaryCourses/get`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryCourseDetailsHandler.Result>(getSecondaryCourseDetails, 'post', url, true, false);
    }

    // post: api/secondaryCourses/hasCourses/${academicYear}
    public academicYearHasCourses(academicYear: number): Promise<AcademicYearHasCoursesHandler.Result> {
        let url = `api/secondaryCourses/hasCourses/${academicYear}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AcademicYearHasCoursesHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/secondaryCourses/save
    public saveSecondaryCourseDetails(saveSecondaryCourseDetails: SaveSecondaryCourseDetailsHandler.Request): Promise<SaveSecondaryCourseDetailsHandler.Result> {
        let url = `api/secondaryCourses/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveSecondaryCourseDetailsHandler.Result>(saveSecondaryCourseDetails, 'post', url, true, false);
    }

    // post: api/secondaryCourses/deleteSecondaryCourse
    public deleteSecondaryCourse(deleteSecondaryCourse: DeleteSecondaryCourseHandler.Request): Promise<DeleteSecondaryCourseHandler.Result> {
        let url = `api/secondaryCourses/deleteSecondaryCourse`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteSecondaryCourseHandler.Result>(deleteSecondaryCourse, 'post', url, true, false);
    }

    // post: api/secondaryCourses/searchForSecondaryCourses
    public searchForSecondaryCourses(searchForSecondaryCourses: SearchForSecondaryCoursesHandler.Request): Promise<SearchForSecondaryCoursesHandler.Result> {
        let url = `api/secondaryCourses/searchForSecondaryCourses`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SearchForSecondaryCoursesHandler.Result>(searchForSecondaryCourses, 'post', url, true, false);
    }

    // post: api/secondaryCourses/secondaryCourses
    public getSecondaryCourses(getSecondaryCourses: GetSecondaryCoursesHandler.Request): Promise<GetSecondaryCoursesHandler.Result> {
        let url = `api/secondaryCourses/secondaryCourses`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetSecondaryCoursesHandler.Result>(getSecondaryCourses, 'post', url, true, false);
    }

    // post: api/secondaryCourses/secondaryCourseImporter
    public secondaryCourseImporter(secondaryCourseImporter: FormData): Promise<any> {
        let url = `api/secondaryCourses/secondaryCourseImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(secondaryCourseImporter, 'post', url, false, false);
    }

    // get: api/secondaryCourses/secondaryCourseTemplate
    public secondaryCourseTemplate(): Promise<any> {
        let url = `api/secondaryCourses/secondaryCourseTemplate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new SecondaryCourseApiService();
export default service;
