// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// GetRegionalPlanningPartnersByAcademicYearHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import RegionalPlanningPartnerDTO from '../models/RegionalPlanningPartnerDTO';
import ResultDTO from '../models/ResultDTO';

interface Request {
    academicYearId: number;
    regionalPlanningPartnerIdentifier: number | null | null;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYearId: 0,
            regionalPlanningPartnerIdentifier: null,
        },
        initValues);
    },
    academicYearId: 'academicYearId',
    regionalPlanningPartnerIdentifier: 'regionalPlanningPartnerIdentifier',
}

interface Result extends ResultDTO {
    regions: RegionalPlanningPartnerDTO[] | null;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            regions: [],
        },
        initValues);
    },
    regions: 'regions',
}

export { Request, Result };